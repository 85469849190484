<template>
  <div>
    <div>
      <!-- <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
              Search
            </button>
          </div>
        </div> -->

      <div class="form-group">
        <label for="exampleFormControlSelect1"
          ><h5 style="width: 200px">
            <i class="bi bi-person-plus"></i> 분반 보고서
          </h5></label
        >
        <!-- <span
            ><button
              type="button"
              class="btn btn-primary btn-sm"
              @click="AddMember"
            >
              신규
            </button>
          </span> -->
        <!-- @change="onChange(this)" -->
        <select
          class="form-control"
          id="holiday"
          name="holiday"
          v-model="holiday"
          @change="getStudentList(this)"
        >
          <option id="" value="00">날짜를 선택하세요.</option>
          <option
            v-for="daylist in dayLists"
            :key="daylist.dday"
            v-bind:value="daylist.dday"
            :id="daylist.dday"
            :selected="daylist.dday == daylist.cday"
          >
            {{ daylist.dday }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <select
        class="form-control"
        id="gradegroup"
        name="gradegroup"
        v-model="gradegroup"
        @change="getGradeGroup()"
        v-show="this.role < 3"
      >
        <option id="" value="00">조회 대상을 선택하세요.</option>
        <option
          v-for="gradelist in gradegrouplist"
          :key="gradelist.grade"
          v-bind:value="gradelist.grade"
          :id="gradelist.grade"
        >
          {{ gradelist.gradename }}
        </option>
      </select>
    </div>
    <p></p>

    <div>
      <ul class="list-group" v-if="memberLists.length > 0">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="memberList in memberLists"
          :key="memberList.no"
          style="padding-left: 0.5rem; padding-right: 0.1rem"
        >
          <div class="row">
            <div class="col-5">
              <b>
                <div class="row">
                  <div class="col-sm-5">
                    <div
                      v-if="memberList.photo !== null"
                      class="form-group mb-2"
                    >
                      <img
                        :src="photourl(memberList.photo)"
                        alt="..."
                        class="img-thumbnail"
                        style="width: 50%"
                      />
                    </div>
                    <div
                      v-else-if="memberList.photo2 !== null"
                      class="form-group mb-2"
                    >
                      <img
                        :src="photourl(memberList.photo2)"
                        alt="..."
                        class="img-thumbnail"
                        style="width: 50%"
                      />
                    </div>                    
                    <div
                      v-else
                      class="form-group mx-sm-3 mb-2"
                      style="text-align: left"
                    >
                      <img src="../assets/noimage.png" style="width: 40%" />
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <span class="align-middle"
                      >{{ memberList.name }}({{ memberList.sex }})</span
                    >
                  </div>
                </div>
              </b>
            </div>
            <div class="col-7" style="text-align: right;">
              <span style="padding-left: 0px" v-if="memberList.attendyn == 'Y'">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="attendcheck(memberList, '1')"
                >
                  부서
                </button>
              </span>
              <span style="padding-left: 0px" v-else>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="attendcheck(memberList, '1')"
                >
                  부서
                </button>
              </span>
              <span style="padding-left: 2px" v-if="memberList.cellattendyn == 'Y'">
                <button
                  type="button"
                  class="btn btn-warning btn-sm"
                  @click="attendcheck(memberList, '2')"
                >
                  분반
                </button>
              </span>
              <span style="padding-left: 2px" v-else>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="attendcheck(memberList, '2')"
                >
                분반
                </button>
              </span>              
              <!-- <span style="padding-left: 10px">
                    <button
                    type="button"
                    class="btn btn-success"
                    @click="attendcheck(memberList,'2')"
                  > 분반 출석 </button>
                </span> -->
              <span style="padding-left: 2px" v-if="memberList.pray != null">
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  @click="reporting(memberList)"
                  v-show="role === '1' || role === '3'"
                >
                  보고서
                </button>
              </span>
              <span style="padding-left: 2px" v-else>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="reporting(memberList)"
                  v-show="role === '1' || role === '3'"
                >
                  보고서
                </button>
              </span>
            </div>
          </div>
        </li>
      </ul>

      <ul class="list-group" v-else>
        <div class="row" style="width: 100%; text-align: center">
          <div class="col-12">조회된 자료가 없습니다.</div>
        </div>
      </ul>
      <!-- <button class="m-3 btn btn-sm btn-danger" @click="removeAllTutorials">
            Remove All
          </button> -->
    </div>

    <div
      class="modal fade"
      id="reportModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="reportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reportModalLabel" style="color: blue">
              <span id="plpmo"></span>
              <div>보고서</div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="addReportForm"
                name="addReportForm"
                v-on:submit="addReportForm"
              >
                <div class="form-group mx-sm-3 mb-2"></div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >{{ form.name }}({{ form.sex }})</label
                  >
                  <textarea
                    class="form-control"
                    id="pl"
                    name="pray"
                    rows="7"
                    v-model="form.pray"
                    required
                    placeholder="기도제목 또는 특이사항을 입력해주세요."
                  ></textarea>
                  <input type="hidden" name="no" v-model="form.no" />
                  <input
                    type="hidden"
                    name="studentid"
                    v-model="form.studentid"
                  />
                  <input
                    type="hidden"
                    name="teacherid"
                    v-model="form.teacherid"
                  />
                  <input type="hidden" name="attenddate" v-model="holiday" />
                  <input type="hidden" name="classid" v-model="form.classid" />
                  <input type="hidden" name="groupid" v-model="form.groupid" />
                  <input type="hidden" name="grade" v-model="form.grade" />
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary" v-if="isNew">
                    등록
                  </button>
                  <button type="submit" class="btn btn-primary" v-else>
                    수정
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <!-- <span>※.</span> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import manageService from "../services/manageService";

  import $ from "jquery";

  export default {
    name: "list",
    component: {},
    data() {
      return {
        dayLists: {},
        pageTotalCount: 0,
        page: 15,
        holiday: "00",
        currentDept: "",
        memberLists: {},
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,
        classid: this.$store.state.classid,
        form: {
          studentid: "",
          teacherid: "",
          no: "",
          attenddate: "",
          pray: "",
          name: "",
          sex: "",
          classid: "",
          groupid: "",
          grade: "",
        },
        isNew: false,
        firstselect: "selected",
        gradegrouplist: {},
        gradegroup: "00",
      };
    },
    created() {
      //this.initGetMemberList(this.currentDept);
    },
    computed: {
      photourl() {
        return (url) => {
          return "./upload/photo/" + url;
        };
      },
    },
    methods: {
      getGradeGroup() {
        if (this.holiday === "00") {
          this.$toast.show("날짜를 먼저 선택해주세요.", {
            type: "warning",
            position: "top-right",
          });
          //this.gradegroup = "00";
          //return;
        }

        const data = {
          attenddate: this.holiday,
          grade: this.gradegroup,
          teacherid: this.form.teacherid,
          role: this.role,
          classid: this.classid,
        };

        manageService
          .getAttendanceList(data)
          .then((response) => {
            this.memberLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      getGradeGroupList() {
        const ndata = {
          classid: this.classid,
          grade: this.gradegroup,
          role: this.role,
        };
console.log("ndata ==  ", ndata );
        // 학년 반을 선택하세요.
        manageService
          .getGradeListAll(ndata)
          .then((response) => {
            //this.manageLists = response.data;
            this.gradegrouplist = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      //개행문자 인식
      getHtmlConvert(str) {
        const replacer = new RegExp("\\n", "g");
        return str.replace(replacer, "<br />");
      },
      getDayList() {
        manageService
          .getDaysList()
          .then((response) => {
            this.dayLists = response.data.slice(0, this.page);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      //전체 리스트 가져오기
      getStudentList(item) {

        console.log("item == ", this.gradegroup);

        //if( this.gradegroup === "00") return;

        const data = {
          attenddate: item.holiday,
          grade: item.grade,
          teacherid: item.teacherid,
          role: this.role,
          classid: this.classid,
        };
       
        manageService
          .getAttendanceList(data)
          .then((response) => {
            this.memberLists = response.data;           
          })
          .catch((e) => {
            console.log(e);
          });

      },

      attendcheck(item, sector) {
        //출석체크
        var checkyn = "";
        if(sector == "1"){  // 부서
          if (item.attendyn == "Y") {
            checkyn = "N";
          } else {
            checkyn = "Y";
          }
        } else {           //반출석
          if (item.cellattendyn == "Y") {
            checkyn = "N";
          } else {
            checkyn = "Y";
          }          
        }


        const data = {
          classid: item.classid,
          studentid: item.studentid,
          grade: item.grade,
          sector: sector,
          holiday: this.holiday,
          teacherid: item.teacherid,
          groupid: item.groupid,
          checkyn: checkyn,          
        };

        manageService
          .setAttendChech(data)
          .then(() => {
            this.$toast.show("출결이 처리되었습니다.", {
              type: "success",
              position: "top-right",
            });
            this.getStudentList(data);
          })
          .catch((e) => {
            console.log(e);
          });
      },

      reporting(item) {
        //리포트 보고서

        this.isNew = true;
        this.form.studentid = item.studentid;
        this.form.teacherid = item.teacherid;
        this.form.no = item.no;
        this.form.attenddate = item.attenddate;
        this.form.pray = item.pray;
        this.form.name = item.name;
        this.form.sex = item.sex;
        this.form.classid = item.classid;
        this.form.groupid = item.groupid;
        this.form.grade = item.grade;

        $("#reportModal").modal("show");
      },

      addReportForm(e) {
        e.preventDefault();
        var object = {};
        var fform = document.getElementById("addReportForm");
        var form = new FormData(fform);
        form.forEach(function (value, key) {
          object[key] = value;
        });

        var jsondata = JSON.stringify(object);

        const data = {
          classid: JSON.parse(jsondata).classid,
          studentid: JSON.parse(jsondata).studentid,
          grade: JSON.parse(jsondata).grade,
          sector: "1",
          holiday: this.holiday,
          teacherid: JSON.parse(jsondata).teacherid,
          groupid: JSON.parse(jsondata).groupid,
          checkyn: "",
        };

        if (JSON.parse(jsondata).pray.length < 2) {
          this.$toast.show("내용을 입력해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }
        //신규 모드
        manageService
          .reportWrite(jsondata)
          .then(() => {
            this.$toast.show("보고서가 등록되었습니다.", {
              type: "success",
              position: "top-right",
            });
            this.getStudentList(data);
            $("#reportModal").modal("hide");
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("보고서가 등록되지 않았습니다.", {
              type: "warning",
              position: "top-right",
            });
          });
      },
    },
    mounted() {
      this.getDayList();
      this.getGradeGroupList();
    },
  };
</script>

<style>
  .list {
    text-align: left;
    max-width: 750px;
    margin: auto;
  }
  .done {
    background: oldlace;
  }
</style>
